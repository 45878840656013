<template> 
 <div class="w-full h-screen">
          <iframe
            title="Mapa de Colombia"
            :src="url_map"
            width="100%"
            style="border: 1px solid rgba(0, 0, 0, 0.1);" 
            height="100%" 
            allowfullscreen
            frameborder="0" >
           </iframe>
    </div>
</template>

<script> 
export default {
  components: { 
  },
  data() {
    return {
      url_map: undefined,
    };
  },
  created(){
    if( process.env.VUE_APP_BASE_URL.includes("//smart")  ){
      this.url_map = "https://sfc-frontend-mapas-dot-sfc-smartsupervision-prod.uc.r.appspot.com/";
    }
    else if(process.env.VUE_APP_BASE_URL.includes("//presmart")){
      this.url_map = "https://sfc-frontend-mapas-dot-sfc-smartsupervision-pre.uc.r.appspot.com/";
      console.log('url_map', process.env);
    }
    else if(process.env.VUE_APP_BASE_URL.includes("//qasmart")){
      this.url_map = "https://sfc-frontend-mapas-dot-sfc-smartsupervision-qa.uc.r.appspot.com/";
      console.log('url_map', process.env);
    }
  }
};
</script>